import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shared-button-holder',
  templateUrl: './button-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonHolderComponent {
  @Input() side: 'start' | 'center' | 'end' = 'end';
}
