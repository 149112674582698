import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { Appearance } from '@shared/util-appearance';
import { Size } from '@shared/util-size';

import { ButtonIconSide } from './models/button-icon-side.type';
import { ButtonType } from './models/button-type.type';

@Component({
  selector: 'shared-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() appearance: Appearance = 'secondary';
  @Input() size: Size = 'base';
  @Input() icon?: string;
  @Input() iconSide: ButtonIconSide = 'left';
  @Input() type: ButtonType = 'button';
  @Input() isDisabled?: boolean;
  @Input() isBlock?: boolean;
  @Input() rounded = false;

  @HostBinding('class') class = 'flex';
}
