import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BadgeModule } from '@shared/ui-badge';
import { ButtonModule } from '@shared/ui-button';

import { CatalogProductThumbnailComponent } from '../catalog/shared/components/catalog-product-thumbnail/catalog-product-thumbnail.component';

import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, BadgeModule, ButtonModule],
  declarations: [SafePipe, CatalogProductThumbnailComponent],
  exports: [
    RouterModule,
    SafePipe,
    CommonModule,
    FormsModule,
    CatalogProductThumbnailComponent,
  ],
})
export class SharedModule {}
