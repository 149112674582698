import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { IconComponent } from '@shared/ui-icon';

import { ToastGlobalConfig } from './models/toast-global-config.model';
import { DefaultNoComponentGlobalConfig, TOAST_CONFIG } from './toast-config';
import { ToastComponent } from './toast.component';

export const DefaultGlobalConfig: ToastGlobalConfig = {
  ...DefaultNoComponentGlobalConfig,
  toastComponent: ToastComponent,
};

@NgModule({
  imports: [CommonModule, IconComponent],
  declarations: [ToastComponent],
  exports: [ToastComponent],
})
export class ToastModule {
  static forRoot(
    config: Partial<ToastGlobalConfig> = {},
  ): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG,
          useValue: {
            default: DefaultGlobalConfig,
            config,
          },
        },
      ],
    };
  }
}
