import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from '@shared/ui-icon';

import { BadgeComponent } from './badge.component';

@NgModule({
  imports: [CommonModule, IconComponent],
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
})
export class BadgeModule {}
