import {
  Inject,
  Injectable,
  Injector,
  NgZone,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Appearance } from '@shared/util-appearance';

import { ToastPackage } from './classes/toast-package';
import { ComponentPortal } from './classes/toast-portal';
import { ToastContainerDirective } from './directives/toast-container.directive';
import { ToastActivateToast } from './models/toast-activate-toast.model';
import { ToastGlobalConfig } from './models/toast-global-config.model';
import { ToastIndividualConfig } from './models/toast-individual-config.model';
import { ToastOverlayService } from './services/toast-overlay.service';
import { TOAST_CONFIG, ToastToken } from './toast-config';
import { ToastInjector, ToastRef } from './toast.injector';

@Injectable({ providedIn: 'root' })
export class ToastService {
  config: ToastGlobalConfig;

  currentlyActive = 0;

  toasts: ToastActivateToast<unknown>[] = [];

  overlayContainer?: ToastContainerDirective;

  previousToastMessage?: string;

  private index = 0;

  constructor(
    @Inject(TOAST_CONFIG) token: ToastToken,
    private overlay: ToastOverlayService,
    private _injector: Injector,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
  ) {
    this.config = {
      ...token.default,
      ...token.config,
    };
  }

  show(
    title?: string,
    message?: string,
    override: Partial<ToastIndividualConfig> = {},
    appearance: Appearance = 'info',
  ): ToastActivateToast<unknown> | null {
    return this._preBuildNotification(
      appearance,
      message,
      title,
      this.applyConfig(override),
    );
  }

  success(
    title?: string,
    message?: string,
    override: Partial<ToastIndividualConfig> = {},
  ): ToastActivateToast<unknown> | null {
    return this._preBuildNotification(
      'success',
      message,
      title,
      this.applyConfig(override),
    );
  }

  danger(
    title?: string,
    message?: string,
    override: Partial<ToastIndividualConfig> = {},
  ): ToastActivateToast<unknown> | null {
    return this._preBuildNotification(
      'danger',
      message,
      title,
      this.applyConfig(override),
    );
  }

  info(
    title?: string,
    message?: string,
    override: Partial<ToastIndividualConfig> = {},
  ): ToastActivateToast<unknown> | null {
    return this._preBuildNotification(
      'info',
      message,
      title,
      this.applyConfig(override),
    );
  }

  warning(
    title?: string,
    message?: string,
    override: Partial<ToastIndividualConfig> = {},
  ): ToastActivateToast<unknown> | null {
    return this._preBuildNotification(
      'warning',
      message,
      title,
      this.applyConfig(override),
    );
  }

  /**
   * Remove all or a single toast by id
   */
  clear(toastId?: number): void {
    for (const toast of this.toasts) {
      if (toastId !== undefined) {
        if (toast.toastId === toastId) {
          toast.toastRef.manualClose();
          return;
        }
      } else {
        toast.toastRef.manualClose();
      }
    }
  }

  /**
   * Remove and destroy a single toast by id
   */
  remove(toastId: number): boolean {
    const found = this._findToast(toastId);
    if (!found) {
      return false;
    }
    found.activeToast.toastRef.close();
    this.toasts.splice(found.index, 1);
    this.currentlyActive -= 1;
    if (!this.config.maxOpened || !this.toasts.length) {
      return false;
    }
    if (
      this.currentlyActive < this.config.maxOpened &&
      this.toasts[this.currentlyActive]
    ) {
      const p = this.toasts[this.currentlyActive].toastRef;
      if (!p.isInactive()) {
        this.currentlyActive += 1;
        p.activate();
      }
    }
    return true;
  }

  /**
   * Determines if toast message is already shown
   */
  findDuplicate(
    title = '',
    message = '',
    resetOnDuplicate: boolean,
    countDuplicates: boolean,
  ): ToastActivateToast<unknown> | null {
    const { includeTitleDuplicates } = this.config;

    for (const toast of this.toasts) {
      const hasDuplicateTitle = includeTitleDuplicates && toast.title === title;
      if (
        (!includeTitleDuplicates || hasDuplicateTitle) &&
        toast.message === message
      ) {
        toast.toastRef.onDuplicate(resetOnDuplicate, countDuplicates);
        return toast;
      }
    }

    return null;
  }

  /** create a clone of global config and apply individual settings */
  private applyConfig(
    override: Partial<ToastIndividualConfig> = {},
  ): ToastGlobalConfig {
    return { ...this.config, ...override };
  }

  /**
   * Find toast object by id
   */
  private _findToast(
    toastId: number,
  ): { index: number; activeToast: ToastActivateToast<unknown> } | null {
    for (let i = 0; i < this.toasts.length; i++) {
      if (this.toasts[i].toastId === toastId) {
        return { index: i, activeToast: this.toasts[i] };
      }
    }
    return null;
  }

  /**
   * Determines the need to run inside angular's zone then builds the toast
   */
  private _preBuildNotification(
    appearance: Appearance,
    message: string | undefined,
    title: string | undefined,
    config: ToastGlobalConfig,
  ): ToastActivateToast<unknown> | null {
    if (config.onActivateTick) {
      return this.ngZone.run(() =>
        this._buildNotification(appearance, message, title, config),
      );
    }
    return this._buildNotification(appearance, message, title, config);
  }

  /**
   * Creates and attaches toast data to component
   * returns the active toast, or in case preventDuplicates is enabled the original/non-duplicate active toast.
   */
  private _buildNotification(
    appearance: Appearance,
    message: string | undefined,
    title: string | undefined,
    config: ToastGlobalConfig,
  ): ToastActivateToast<unknown> | null {
    if (!config.toastComponent) {
      throw new Error('toastComponent required');
    }

    // max opened and auto dismiss = true
    // if timeout = 0 resetting it would result in setting this.hideTime = Date.now(). Hence, we only want to reset timeout if there is
    // a timeout at all
    const duplicate = this.findDuplicate(
      title,
      message,
      this.config.resetTimeoutOnDuplicate && config.timeOut > 0,
      this.config.countDuplicates,
    );
    if (
      ((this.config.includeTitleDuplicates && title) || message) &&
      this.config.preventDuplicates &&
      duplicate !== null
    ) {
      return duplicate;
    }

    this.previousToastMessage = message;
    let keepInactive = false;
    if (
      this.config.maxOpened &&
      this.currentlyActive >= this.config.maxOpened
    ) {
      keepInactive = true;
      if (this.config.autoDismiss) {
        this.clear(this.toasts[0].toastId);
      }
    }

    const overlayRef = this.overlay.create(
      config.position,
      this.overlayContainer,
    );
    this.index += 1;
    let sanitizedMessage: string | undefined | null = message;
    if (message && config.enableHtml) {
      sanitizedMessage = this.sanitizer.sanitize(SecurityContext.HTML, message);
    }

    const toastRef = new ToastRef(overlayRef);
    const toastPackage = new ToastPackage(
      this.index,
      config,
      sanitizedMessage,
      title,
      appearance,
      toastRef,
    );
    const toastInjector = new ToastInjector(toastPackage, this._injector);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const component = new ComponentPortal(config.toastComponent, toastInjector);
    const portal = overlayRef.attach(component, this.config.newestOnTop);
    toastRef.componentInstance = portal.instance;
    const ins: ToastActivateToast<unknown> = {
      toastId: this.index,
      title: title || '',
      message: message || '',
      toastRef,
      onShown: toastRef.afterActivate(),
      onHidden: toastRef.afterClosed(),
      onTap: toastPackage.onTap(),
      onAction: toastPackage.onAction(),
      portal,
    };

    if (!keepInactive) {
      this.currentlyActive += 1;
      setTimeout(() => {
        ins.toastRef.activate();
      });
    }

    this.toasts.push(ins);
    return ins;
  }
}
