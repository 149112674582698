import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from '@shared/ui-icon';

import { ButtonHolderComponent } from './button-holder.component';
import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, IconComponent],
  declarations: [ButtonComponent, ButtonHolderComponent],
  exports: [ButtonComponent, ButtonHolderComponent],
})
export class ButtonModule {}
