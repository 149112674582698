/* eslint-disable */
import { Injectable } from '@angular/core';

import { CatalogCategoryProduct } from '../../../catalog/catalog-category/catalog-category-models';
import { Checkout } from '../../../catalog/shared/models/checkout.model';

const TOKEN_PREFIX = 'SHINJU_';
const CHECKOUT = 'CHECKOUT';
const SHIPMENT_COUNTRY = 'SHIPMENT_COUNTRY';
const LAST_VIEWED = 'LAST_VIEWED';

@Injectable()
export class LocalStorageService {
  constructor() {}

  getLastViewed(): Array<CatalogCategoryProduct> {
    return this.getItem(LAST_VIEWED);
  }

  /**
   * Set the Checkout.
   *
   * @param {LastViewed} userInfo
   * @returns {void}
   *
   * @memberOf LocalStorageService
   */
  setLastViewed(LastViewed: Array<CatalogCategoryProduct>): void {
    if (LastViewed !== undefined) {
      this.setItem(LAST_VIEWED, LastViewed);
    } else {
      this.removeItem(LAST_VIEWED);
    }
  }

  /**
   * Get the Checkout.
   *
   * @returns {Checkout}
   *
   * @memberOf LocalStorageService
   */
  getCheckout(): Checkout {
    return this.getItem(CHECKOUT);
  }

  /**
   * Set the Checkout.
   *
   * @param {Checkout} userInfo
   * @returns {void}
   *
   * @memberOf LocalStorageService
   */
  setCheckout(checkout?: Checkout): void {
    if (checkout !== undefined) {
      this.setItem(CHECKOUT, checkout);
    } else {
      this.removeItem(CHECKOUT);
    }
  }

  getShipmentCountry(): number {
    const item = this.getItem(SHIPMENT_COUNTRY);
    if (item === null) {
      return 0;
    } else {
      return item;
    }
  }

  setShipmentCountry(shipmentCountry: number): void {
    if (shipmentCountry !== undefined) {
      this.setItem(SHIPMENT_COUNTRY, shipmentCountry);
    } else {
      this.removeItem(SHIPMENT_COUNTRY);
    }
  }

  /**
   * Get item
   * @returns {UserInfo}
   */
  getItem(key: string) {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(TOKEN_PREFIX + key));
  }

  // @ts-ignore
  setItem(key: string, item): void {
    return localStorage.setItem(TOKEN_PREFIX + key, JSON.stringify(item));
  }

  /**
   * Remove Item.
   *
   * @param {string} key
   * @returns {void}
   *
   * @memberOf LocalStorageService
   */
  removeItem(key: string): void {
    return localStorage.removeItem(TOKEN_PREFIX + key);
  }

  /**
   * Clear storage data.
   */
  clear(): void {
    localStorage.removeItem(CHECKOUT);
  }
}
