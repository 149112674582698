import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
} from '@angular/core';

import { BasePortalHost, ComponentPortal } from './toast-portal';

/**
 * A PortalHost for attaching portals to an arbitrary DOM element outside of the Angular
 * application context.
 *
 * This is the only part of the portal core that directly touches the DOM.
 */
export class ToastDomPortalHost extends BasePortalHost {
  constructor(
    private _hostDomElement: Element,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
  ) {
    super();
  }

  /**
   * Attach the given ComponentPortal to DOM element using the ComponentFactoryResolver.
   * @param portal Portal to be attached
   */
  attachComponentPortal<T>(
    portal: ComponentPortal<T>,
    newestOnTop: boolean,
  ): ComponentRef<T> {
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(portal.component);
    const componentRef = componentFactory.create(portal.injector);

    this._appRef.attachView(componentRef.hostView);

    this.setDisposeFn(() => {
      this._appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });

    if (newestOnTop) {
      this._hostDomElement.insertBefore(
        this.getComponentRootNode(componentRef),
        this._hostDomElement.firstChild,
      );
    } else {
      this._hostDomElement.appendChild(this.getComponentRootNode(componentRef));
    }

    return componentRef;
  }

  private getComponentRootNode(
    componentRef: ComponentRef<unknown>,
  ): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<unknown>)
      .rootNodes[0] as HTMLElement;
  }
}
