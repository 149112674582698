<div aria-live="assertive" class="inset-0 mb-3 pointer-events-none">
  <div class="space-y-4">
    <div
      [class.border-blue-500]="appearance === 'info'"
      [class.border-green-500]="appearance === 'success'"
      [class.border-red-500]="appearance === 'danger'"
      [class.border-yellow-500]="appearance === 'warning'"
      class="max-w-sm w-full mx-auto bg-white dark:bg-gray-700 shadow-lg rounded pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden border-t-8"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            @if (appearance === 'success') {
              <shared-icon
                [name]="'check-circle'"
                [size]="'3xl'"
                class="text-green-400"
              ></shared-icon>
            }
            @if (appearance === 'danger') {
              <shared-icon
                [name]="'x-circle'"
                [size]="'3xl'"
                class="text-red-400"
              ></shared-icon>
            }
            @if (appearance === 'warning') {
              <shared-icon
                [name]="'exclamation-circle'"
                [size]="'3xl'"
                class="text-yellow-400"
              ></shared-icon>
            }
            @if (appearance === 'info') {
              <shared-icon
                [name]="'info-circle'"
                [size]="'3xl'"
                class="text-blue-400"
              ></shared-icon>
            }
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            @if (title) {
              <p class="font-semibold text-sm text-gray-900 dark:text-gray-100">
                {{ title }}
                @if (duplicatesCount) {
                  [{{ duplicatesCount + 1 }}]
                }
              </p>
            }
            @if (message) {
              <p class="mt-1 text-sm text-gray-500 dark:text-white">
                {{ message }}
              </p>
            }
            <!--<div *ngIf="options.progressBar">-->
            <!--  <div class="toast-progress" [style.width]="width + '%'"></div>-->
            <!--</div>-->
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              class="bg-white dark:bg-gray-700 rounded inline-flex text-gray-400 dark:text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <shared-icon [name]="'x'" [size]="'3xl'"></shared-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
