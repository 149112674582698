<i
  [class.text-2xl]="size === '2xl'"
  [class.text-3xl]="size === '3xl'"
  [class.text-4xl]="size === '4xl'"
  [class.text-5xl]="size === '5xl'"
  [class.text-base]="size === 'base'"
  [class.text-lg]="size === 'lg'"
  [class.text-sm]="size === 'sm'"
  [class.text-xl]="size === 'xl'"
  [class]="'bi bi-' + name"
>
</i>
