/* eslint-disable */
import { EventEmitter, Injectable } from '@angular/core';

import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';

import { Checkout, CheckoutCartProduct } from './../models/checkout.model';
import { ToastService } from '@shared/ui-toast';

@Injectable()
export class CatalogCheckoutService {
  eventEmitter: EventEmitter<Checkout> = new EventEmitter<Checkout>();
  checkout: Checkout;

  constructor(
    private localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
  ) {
    this.checkout = this.localStorageService.getCheckout();

    if (this.checkout === null) {
      this.setEmptyCheckout();
    }
  }

  /**
   * Add a Product to the Checkout (Local Storage).
   *
   * @param {CheckoutCartProduct} product
   *
   * @memberOf CatalogCheckoutService
   */
  addProduct(product: CheckoutCartProduct): void {
    const index = this.checkout.cart.products.findIndex(
      (i) => i.url === product.url,
    );

    if (index === -1) {
      this.checkout.cart.products.push(product);
      this.toastService.success(
        'Product toegevoegd',
        `${product.name} toegevoegd aan de winkelwagen`,
      );
    } else {
      this.checkout.cart.products[index].sum += product.sum;

      if (this.checkout.cart.products[index].sum > product.stock) {
        this.checkout.cart.products[index].sum = product.stock;
        this.toastService.warning(
          'Niet op voorraad',
          `Er zijn ${this.checkout.cart.products[index].sum} aantallen op voorraad van ${product.name}`,
        );
      } else {
        this.toastService.success(
          'Product toegevoegd',
          `${product.name} toegevoegd aan de winkelwagen`,
        );
      }
    }

    this.setCheckout();
  }

  /**
   * Remove Product By Index.
   *
   * @param {number} index
   *
   * @memberOf CatalogCheckoutService
   */
  removeProduct(index: number): void {
    this.checkout.cart.products.splice(index, 1);
    this.setCheckout();
  }

  /**
   * Change the Sum of a Product.
   *
   * @param {number} index
   * @param {number} sum
   *
   * @memberOf CatalogCheckoutService
   */
  changeSumProduct(index: number, sum: number) {
    this.checkout.cart.products[index].sum = sum;
    this.setCheckout();
  }

  setProducts(products: CheckoutCartProduct[]) {
    this.checkout.cart.products = products;
    this.setCheckout();
  }

  /**
   * Set Checkout.
   *
   * @private
   *
   * @memberOf CatalogCheckoutService
   */
  public setCheckout() {
    this.localStorageService.setCheckout(this.checkout);
    this.eventEmitter.emit(this.checkout);
  }

  /**
   * New Checkout.
   *
   * @private
   *
   * @memberOf CatalogCheckoutService
   */
  public setEmptyCheckout(): void {
    const result = {
      cart: {
        products: [],
      },
    };
    this.checkout = result;
    this.setCheckout();
  }
}
