<span
  [class.text-white]="
    appearance === 'primary' ||
    appearance === 'secondary' ||
    appearance === 'dark'
  "
  [ngClass]="{
    rounded: rounded,
    'rounded-full': !rounded,
    'text-xs px-2.5': size === 'sm',
    'text-sm px-3': size === 'base',
    'bg-primary-500': appearance === 'primary',
    'bg-secondary-600': appearance === 'secondary',
    'bg-gray-100 text-gray-800': appearance === 'light',
    'bg-gray-800': appearance === 'dark',
    'bg-red-100 text-red-800': appearance === 'danger',
    'bg-blue-100 text-blue-800': appearance === 'info',
    'bg-green-100 text-green-800': appearance === 'success',
    'bg-yellow-100 text-yellow-800': appearance === 'warning'
  }"
  class="inline-flex items-center py-0.5"
>
  @if (icon && iconSide === 'left') {
    <shared-icon [name]="icon" class="mr-2"></shared-icon>
  }
  <ng-content></ng-content>
  @if (icon && iconSide === 'right') {
    <shared-icon [name]="icon" class="ml-2"></shared-icon>
  }
  @if (closable) {
    <shared-icon
      (mousedown)="onClose($event)"
      [name]="'x'"
      class="ml-2 cursor-pointer"
    ></shared-icon>
  }
</span>
