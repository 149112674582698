<button
  [class.hover:bg-primary-600]="
    appearance === 'secondary' || appearance === 'primary'
  "
  [class.text-primary-600]="appearance === 'link' || appearance === 'secondary'"
  [class.text-white]="appearance !== 'secondary' && appearance !== 'light'"
  [ngClass]="{
    'border border-transparent': appearance !== 'secondary',
    'px-2 py-1 text-xs': size === 'sm',
    'px-3 py-1 text-sm': size === 'base',
    'px-6 py-3 text-lg': size === 'lg',
    'shadow-sm': appearance !== 'link',
    'bg-primary-500 focus:ring-primary-500': appearance === 'primary',
    'bg-red-600 hover:bg-red-700 focus:ring-red-500': appearance === 'danger',
    'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500':
      appearance === 'warning',
    'bg-green-600 hover:bg-green-700 focus:ring-green-500':
      appearance === 'success',
    'bg-gray-700 hover:bg-gray-800 focus:ring-gray-600': appearance === 'dark',
    'bg-gray-200 hover:bg-gray-300 focus:ring-gray-100': appearance === 'light',
    'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': appearance === 'info',
    'hover:text-white focus:ring-primary-500 border-2 border-primary-600 hover:border-primary-700':
      appearance === 'secondary',
    'hover:text-primary-700': appearance === 'link',
    rounded: !rounded,
    'rounded-full': rounded
  }"
  class="inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
  type="button"
>
  <ng-container *ngTemplateOutlet="tmpl"></ng-container>
</button>

<ng-template #tmpl>
  @if (icon && iconSide === 'left') {
    <shared-icon
      [class.mr-1]="size === 'sm' && ngContent.innerHTML.trim()"
      [class.mr-2]="size !== 'sm' && ngContent.innerHTML.trim()"
      [name]="icon"
    ></shared-icon>
  }
  <span #ngContent><ng-content></ng-content></span>
  @if (icon && iconSide === 'right') {
    <shared-icon
      [class.ml-1]="size === 'sm' && ngContent.innerHTML.trim()"
      [class.ml-2]="size !== 'sm' && ngContent.innerHTML.trim()"
      [name]="icon"
    ></shared-icon>
  }
</ng-template>
