<a
  [routerLink]="['/product/' + dataSource.url]"
  class="block h-full group text-sm relative"
>
  <div
    (mouseout)="onMouseOut()"
    (mouseover)="onMouseOver()"
    class="w-full mb-4 aspect-w-1 aspect-h-1 rounded overflow-hidden bg-gray-100 group-hover:opacity-75"
  >
    <img
      alt="{{ dataSource.name }}"
      class="w-full h-full object-center object-cover"
      src="{{ url + imgSrc }}"
    />
    <div class="leading-none flex flex-col absolute top-0">
      @if (dataSource.salePrice !== null) {
        <span class="text-white rounded-sm p-1 bg-red-600 mt-1 self-start"
          >-{{ discount }} %</span
        >
      }
      @if (dataSource.stock === 0 || dataSource.stock === null) {
        <span class="text-white rounded-sm p-1 bg-primary-400 mt-1 self-start"
          >Uitverkocht</span
        >
      }
    </div>
  </div>
  <h3 class="font-medium text-gray-900">
    {{ dataSource.name }}
  </h3>
  <p class="text-gray-500 italic">
    {{ dataSource.brand }}
  </p>
  <p class="mt-2">
    <!--    font-medium text-gray-900-->
    <span
      [ngClass]="{ 'text-red-600': dataSource.salePrice !== null }"
      class="font-medium text-gray-900"
    >
      {{ dataSource.price | currency: 'EUR' : 'symbol' : '1.2-2' }}
    </span>
    @if (dataSource.salePrice !== null) {
      <span
        [ngClass]="{
          'line-through text-gray-500 ml-1': dataSource.salePrice !== null
        }"
      >
        {{ dataSource.salePrice | currency: 'EUR' : 'symbol' : '1.2-2' }}
      </span>
    }
  </p>
  @if (dataSource.stock > 0) {
    <div class="absolute bottom-0 right-0 hidden group-hover:block">
      <shared-button
        [appearance]="'dark'"
        (click)="onOrder($event)"
        [icon]="'cart'"
        [rounded]="true"
      ></shared-button>
    </div>
  }
</a>
