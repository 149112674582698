import { InjectionToken } from '@angular/core';

import { ToastGlobalConfig } from './models/toast-global-config.model';
import { ToastPosition } from './models/toast-position.enum';

export const DefaultNoComponentGlobalConfig: ToastGlobalConfig = {
  maxOpened: 0,
  autoDismiss: false,
  newestOnTop: true,
  preventDuplicates: false,
  countDuplicates: false,
  resetTimeoutOnDuplicate: false,
  includeTitleDuplicates: false,

  // Individual
  disableTimeOut: false,
  timeOut: 3000,
  extendedTimeOut: 3000,
  enableHtml: false,
  progressBar: false,
  position: ToastPosition.BottomRight,
  tapToDismiss: true,
  onActivateTick: false,
};

export interface ToastToken {
  default: ToastGlobalConfig;
  config: Partial<ToastGlobalConfig>;
}

export const TOAST_CONFIG = new InjectionToken<ToastToken>('TOAST_CONFIG');
