import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Appearance } from '@shared/util-appearance';
import { Size } from '@shared/util-size';

import { BadgeIconSide } from './models/badge-icon-side.type';

@Component({
  selector: 'shared-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() appearance: Appearance = 'light';
  @Input() size: Size = 'sm';
  @Input() icon?: string;
  @Input() iconSide: BadgeIconSide = 'left';
  @Input() rounded = false;
  @Input() closable = false;
  @Output() closed: EventEmitter<Event> = new EventEmitter();

  onClose(event: Event): void {
    this.closed.emit(event);
  }
}
