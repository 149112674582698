/* eslint-disable */
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CatalogCheckoutService } from '../../services/catalog-checkout.service';
import { Router } from '@angular/router';
import { ToastService } from '@shared/ui-toast';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-catalog-product-thumbnail',
  templateUrl: './catalog-product-thumbnail.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogProductThumbnailComponent implements OnInit {
  @Input() dataSource: any = {};
  discount?: string;
  url = ''; // ENVIRONMENT.url;
  imgSrc?: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly catalogCheckoutService: CatalogCheckoutService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {}

  ngOnInit(): void {
    if (this.dataSource.salePrice) {
      const a = this.dataSource.salePrice - this.dataSource.price;
      const b = this.dataSource.salePrice / a;
      const c = 100 / b;
      this.discount = c.toFixed(0);
    }

    this.imgSrc = this.dataSource.image;
  }

  onMouseOver(): void {
    if (this.dataSource.imageHover) {
      this.imgSrc = this.dataSource.imageHover;
      this.cdr.detectChanges();
    }
  }

  onMouseOut(): void {
    this.imgSrc = this.dataSource.image;
    this.cdr.detectChanges();
  }

  onOrder(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dataSource.stock === 0) {
      return;
    }

    // Map Product to Product for the Cart / Checkout.
    this.catalogCheckoutService.addProduct({
      url: this.dataSource.url,
      image: this.dataSource.image,
      name: this.dataSource.name,
      sum: 1,
      price: this.dataSource.price,
      // @ts-ignore
      salePrice: this.dataSource.salePrice,
      deliveryTime: 1,
      // @ts-ignore
      stock: this.dataSource.stock,
      brand: {
        name: this.dataSource.brand,
        url: '',
        image: '',
      },
    });

    //this.router.navigate(['checkout/cart']);
  }
}
