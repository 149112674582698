import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { Appearance } from '@shared/util-appearance';

import { ToastPackage } from './classes/toast-package';
import { ToastIndividualConfig } from './models/toast-individual-config.model';
import { ToastService } from './toast.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[shared-ui-toast]',
  templateUrl: './toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy {
  message?: string | null;
  title?: string;
  config: ToastIndividualConfig;
  duplicatesCount!: number;

  originalTimeout: number;

  /** width of progress bar */
  width = -1;

  appearance: Appearance;

  /** controls animation */
  @HostBinding('@flyInOut')
  state = {
    value: 'inactive',
    params: {
      easeTime: 300,
      easing: 'ease-in',
    },
  };

  private timeout?: number;

  private intervalId?: number;

  private hideTime!: number;

  private sub: Subscription;

  private sub1: Subscription;

  private sub2: Subscription;

  private sub3: Subscription;

  constructor(
    protected toastrService: ToastService,
    public toastPackage: ToastPackage,
    protected ngZone?: NgZone,
  ) {
    this.message = toastPackage.message;
    this.title = toastPackage.title;
    this.config = toastPackage.config;
    this.originalTimeout = toastPackage.config.timeOut;
    this.appearance = toastPackage.appearance;
    this.sub = toastPackage.toastRef.afterActivate().subscribe(() => {
      this.activateToast();
    });
    this.sub1 = toastPackage.toastRef.manualClosed().subscribe(() => {
      this.onRemove();
    });
    this.sub2 = toastPackage.toastRef.timeoutReset().subscribe(() => {
      this.resetTimeout();
    });
    this.sub3 = toastPackage.toastRef
      .countDuplicate()
      .subscribe((count: number) => {
        this.duplicatesCount = count;
      });
  }

  /** hides component when waiting to be displayed */
  @HostBinding('style.display')
  get displayStyle(): string | undefined {
    return this.state.value === 'inactive' ? 'none' : undefined;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    if (this.intervalId) clearInterval(this.intervalId);
    if (this.timeout) clearTimeout(this.timeout);
  }

  /**
   * activates toast and sets timeout
   */
  activateToast(): void {
    this.state = { ...this.state, value: 'active' };
    if (
      !(
        this.config.disableTimeOut === true ||
        this.config.disableTimeOut === 'timeOut'
      ) &&
      this.config.timeOut
    ) {
      this.outsideTimeout(() => this.onRemove(), this.config.timeOut);
      this.hideTime = new Date().getTime() + this.config.timeOut;
      if (this.config.progressBar) {
        this.outsideInterval(() => this.updateProgress(), 10);
      }
    }
  }

  /**
   * updates progress bar width
   */
  updateProgress(): void {
    if (this.width === 0 || this.width === 100 || !this.config.timeOut) {
      return;
    }
    const now = new Date().getTime();
    const remaining = this.hideTime - now;
    this.width = (remaining / this.config.timeOut) * 100;
    if (this.width <= 0) {
      this.width = 0;
    }
    if (this.width >= 100) {
      this.width = 100;
    }
  }

  resetTimeout(): void {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.intervalId) clearInterval(this.intervalId);
    this.state = { ...this.state, value: 'active' };

    this.outsideTimeout(() => this.onRemove(), this.originalTimeout);
    this.config.timeOut = this.originalTimeout;
    this.hideTime = new Date().getTime() + (this.config.timeOut || 0);
    this.width = -1;
    if (this.config.progressBar) {
      this.outsideInterval(() => this.updateProgress(), 10);
    }
  }

  /**
   * tells toastrService to remove this toast after animation time
   */
  onRemove(): void {
    if (this.state.value === 'removed') {
      return;
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.state = { ...this.state, value: 'removed' };
    this.outsideTimeout(
      () => this.toastrService.remove(this.toastPackage.toastId),
      300,
    );
  }

  @HostListener('click') tapToast(): void {
    if (this.state.value === 'removed') {
      return;
    }
    this.toastPackage.triggerTap();
    if (this.config.tapToDismiss) {
      this.onRemove();
    }
  }

  @HostListener('mouseenter') stickAround(): void {
    if (this.state.value === 'removed') {
      return;
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.config.timeOut = 0;
    this.hideTime = 0;

    // disable progressBar
    if (this.intervalId) clearInterval(this.intervalId);
    this.width = 0;
  }

  @HostListener('mouseleave') delayedHideToast(): void {
    if (
      this.config.disableTimeOut === true ||
      this.config.disableTimeOut === 'extendedTimeOut' ||
      this.config.extendedTimeOut === 0 ||
      this.state.value === 'removed'
    ) {
      return;
    }
    this.outsideTimeout(() => this.onRemove(), this.config.extendedTimeOut);
    this.config.timeOut = this.config.extendedTimeOut;
    this.hideTime = new Date().getTime() + (this.config.timeOut || 0);
    this.width = -1;
    if (this.config.progressBar) {
      this.outsideInterval(() => this.updateProgress(), 10);
    }
  }

  outsideTimeout(func: () => void, timeout: number): void {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(
        () =>
          (this.timeout = setTimeout(
            () => this.runInsideAngular(func),
            timeout,
          ) as unknown as number),
      );
    } else {
      this.timeout = setTimeout(() => func(), timeout) as unknown as number;
    }
  }

  outsideInterval(func: () => void, timeout: number): void {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(
        () =>
          (this.intervalId = setInterval(
            () => this.runInsideAngular(func),
            timeout,
          ) as unknown as number),
      );
    } else {
      this.intervalId = setInterval(() => func(), timeout) as unknown as number;
    }
  }

  private runInsideAngular(func: () => void): void {
    if (this.ngZone) {
      this.ngZone.run(() => func());
    } else {
      func();
    }
  }
}
