import { Observable, Subject } from 'rxjs';

import { Appearance } from '@shared/util-appearance';

import { ToastIndividualConfig } from '../models/toast-individual-config.model';
import { ToastRef } from '../toast.injector';

export class ToastPackage {
  private _onTap = new Subject<void>();

  private _onAction = new Subject<void>();

  constructor(
    public toastId: number,
    public config: ToastIndividualConfig,
    public message: string | null | undefined,
    public title: string | undefined,
    public appearance: Appearance,
    public toastRef: ToastRef<unknown>,
  ) {
    this.toastRef.afterClosed().subscribe(() => {
      this._onAction.complete();
      this._onTap.complete();
    });
  }

  /**
   *  Fired on click
   */
  triggerTap(): void {
    this._onTap.next();
    if (this.config.tapToDismiss) {
      this._onTap.complete();
    }
  }

  onTap = (): Observable<void> => this._onTap.asObservable();

  onAction = (): Observable<void> => this._onAction.asObservable();
}
