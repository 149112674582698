/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
} from '@angular/core';

import { ToastDomPortalHost } from '../classes/toast-dom-portal-host';
import { ToastOverlayRef } from '../classes/toast-overlay-ref';
import { ToastContainerDirective } from '../directives/toast-container.directive';
import { ToastPosition } from '../models/toast-position.enum';

import { ToastOverlayContainerService } from './toast-overlay-container.service';

/**
 * Service to create Overlays. Overlays are dynamically added pieces of floating UI, meant to be
 * used as a low-level building building block for other components. Dialogs, tooltips, menus,
 * selects, etc. can all be built using overlays. The service should primarily be used by authors
 * of re-usable components rather than developers building end-user applications.
 *
 * An overlay *is* a Por talHost, so any kind of Portal can be loaded into one.
 */
@Injectable({ providedIn: 'root' })
export class ToastOverlayService {
  // Namespace panes by overlay container
  private _paneElements: Map<
    ToastContainerDirective,
    Record<string, HTMLElement>
  > = new Map();

  constructor(
    private _overlayContainer: ToastOverlayContainerService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  /**
   * Creates an overlay.
   * @returns A reference to the created overlay.
   */
  create(
    position?: ToastPosition,
    overlayContainer?: ToastContainerDirective,
  ): ToastOverlayRef {
    // get existing pane if possible
    return this._createOverlayRef(
      this.getPaneElement(position, overlayContainer),
    );
  }

  getPaneElement(
    position: ToastPosition = ToastPosition.TopRight,
    overlayContainer?: ToastContainerDirective,
  ): HTMLElement {
    if (!this._paneElements.get(overlayContainer as ToastContainerDirective)) {
      this._paneElements.set(overlayContainer as ToastContainerDirective, {});
    }

    if (
      !this._paneElements.get(overlayContainer as ToastContainerDirective)![
        position
      ]
    ) {
      this._paneElements.get(overlayContainer as ToastContainerDirective)![
        position
      ] = this._createPaneElement(position, overlayContainer);
    }

    return this._paneElements.get(overlayContainer as ToastContainerDirective)![
      position
    ];
  }

  /**
   * Creates the DOM element for an overlay and appends it to the overlay container.
   * @returns Newly-created pane element
   */
  private _createPaneElement(
    position: ToastPosition,
    overlayContainer?: ToastContainerDirective,
  ): HTMLElement {
    const pane = this._document.createElement('div');

    pane.id = 'shared-ui-toast-container';

    pane.classList.add('fixed');
    pane.classList.add('w-96');
    pane.classList.add('z-50');

    switch (position) {
      case ToastPosition.TopRight:
        pane.classList.add('top-6');
        pane.classList.add('right-3');
        break;
      case ToastPosition.BottomRight:
        pane.classList.add('bottom-6');
        pane.classList.add('right-3');
        break;
      case ToastPosition.BottomLeft:
        pane.classList.add('bottom-6');
        pane.classList.add('left-3');
        break;
      case ToastPosition.TopLeft:
        pane.classList.add('top-6');
        pane.classList.add('left-3');
        break;
      case ToastPosition.TopCenter:
        pane.classList.add('top-6');
        pane.classList.add('right-0');
        pane.classList.add('w-full');
        break;
      case ToastPosition.BottomCenter:
        pane.classList.add('bottom-3');
        pane.classList.add('right-0');
        pane.classList.add('w-full');
        break;
    }

    if (!overlayContainer) {
      this._overlayContainer.getContainerElement().appendChild(pane);
    } else {
      overlayContainer.getContainerElement().appendChild(pane);
    }

    return pane;
  }

  /**
   * Create a DomPortalHost into which the overlay content can be loaded.
   * @param pane The DOM element to turn into a portal host.
   * @returns A portal host for the given DOM element.
   */
  private _createPortalHost(pane: HTMLElement): ToastDomPortalHost {
    return new ToastDomPortalHost(
      pane,
      this._componentFactoryResolver,
      this._appRef,
    );
  }

  /**
   * Creates an OverlayRef for an overlay in the given DOM element.
   * @param pane DOM element for the overlay
   */
  private _createOverlayRef(pane: HTMLElement): ToastOverlayRef {
    return new ToastOverlayRef(this._createPortalHost(pane));
  }
}
