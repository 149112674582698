import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Size } from '@shared/util-size';

@Component({
  selector: 'shared-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class IconComponent {
  @Input() name!: string;

  @Input() size?: Size;
}
